<template>
  <div>
    <component
      :is="component"
      :param.sync="param"
      :educationDate.sync="educationDate"
      :educationInfo.sync="educationInfo"
      :saveCall.sync="saveCall"
      @setData="setData"
    />
    <!-- <div class="col float-right">
      <q-btn-group outline>
        <template v-if="!checkAppr">
          <font class="text-negative" style="font-size:0.9em;font-weight:500;">
            ※ 연간교육계획에 수립된 교육입니다. 연간교육계획 결재가 승인 된 후 계획완료 기능을 이용하실 수 있습니다.
          </font>
        </template>
        <c-update-btn 
          name="updateBtn"
          :data="param.planUpdateBtnData"
          :btnEditable="btnEditable"
          :flagCondition="flagCondition"
          @back="back"
        />
        <c-btn  v-show="editable&&!disabled" :showLoading="false" label="교육계획결재" icon="save" color="blue" @btnClicked="planAppr" /> 
        <c-btn v-show="editable&&param.eduEducationId&&!disabled&&checkAppr" label="삭제" :editable="editable" icon="delete_forever" @btnClicked="remove" />
        <c-btn  v-show="editable&&!disabled&&checkAppr" :showLoading="false" icon="check" label="완료"  @btnClicked="planComplete('COMPLETE')" />
      </q-btn-group>
    </div>
    <c-tab
      type="vertical"
      :tabItems="tabItems"
      :height.sync="tabHeight"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :educationInfo.sync="educationInfo"
          :educationDate="educationDate"
          :param="param"
          @setData="setData"
        />
      </template>
    </c-tab> -->
  </div>
</template>

<script>

import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
export default {
  name: 'education-plan-tab',
  props: {
    param: {
      type: Object,
      default: () => ({
        eduPlanTime: [],
        plantCd: null,
        eduEducationId: '',
        eduCourseId: '',
        stepCd: '',
        planUpdateBtnData: {
          title: 'LBLPLAN',
          flag: false,
          research: '',
          planResearch: '',
        },
      }),
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      educationInfo: {
        eduEducationId: '',
        eduEducationYearPlanId: '',
        educationName: '',
        eduCourseId: '',
        plantCd: '',
        educationYear: '',
        educationCourseName: '',
        educationKindCdLarge: null,
        educationKindCdSmall: null,
        educationTypeCd: null,
        departmentDeptCd: '',
        legalEducationFlag: null,
        sumEducationTime: '',
        educationCycle: null,
        educationPurpose: '',
        relationLaws: '',
        mainTargetAudience: '',
        educationMonth: null,
        educationLocation: '',
        estimatedEducationExpenses: '',
        executionBudget: '',
        educationStartDate: '',
        educationEndDate: '',
        educationStartTime: '',
        educationEndTime: '',
        instructorInformation: '',
        sopMocId: '',
        educationMethodCd: null,
        educationOverview: '',
        evaluationEvalCd: '',
        documentStatusCd: '',
        qrCodeRoute: '',
        checkAppr: '',
        // 위험성평가 결과 항목 추가
        ramRiskAssessmentPlanId: '',
        assessmentName: '',
        ramTechniqueCd: null,
        ramStepCd: '',

        // 자체감사 항목 추가
        selfInspectionResultId: '',
        selfInspectionStatusCd: '',
        sysRevision: '',
        selfInspectionTitle: '',

        eduQuestionFlag: 'N',
        eduQuestionMstId: '',
        eduQuestionPassScore: null,
        regUserId: '',
        chgUserId: '',
        vod: null,

        eduSubjectList: [], // 과정별 교육과목
        delEduSubjectList: [],
        eduAttendeeList: [], // 대상자
        delEduAttendeeList: [],
      },
      saveCall: {
        saveCallData: '',
      },
      editable: true,
      splitter: 5,
      tab: 'educationPlanInfo',
      
      isComplete: false,
      getUrl: '',
      deleteUrl: '',
      educationDate: '',
      component: () => import(`${'./educationPlanInfo.vue'}`),
      // tabItems: [],
    };
  },
  computed: {
    tabHeight() {
      let numHeight = this.height ? Number(this.$_.replace(this.height, 'px', '')) : 600;
      numHeight = numHeight - 40
      return numHeight + 'px';
    },
    checkAppr() {
      // true 인 경우 쓰기가능
      return this.educationInfo.eduEducationYearPlanId ? this.educationInfo.checkAppr === 'ASC9999999' : true;
    },
    disabled() {
      return this.educationInfo.documentStatusCd !== 'ESC000001'
    },
    flagCondition() {
      return this.educationInfo.documentStatusCd === 'ESC000001'
    },
    btnEditable() {
      return this.editable && this.educationInfo.documentStatusCd === 'ESC000005' && Boolean(this.educationInfo.eduEducationId)
    },
  },
  watch: {
    'param.planUpdateBtnData.research'() {
      this.getDetail();
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.getUrl = selectConfig.sop.edu.result.get.url;
      // this.deleteUrl = transactionConfig.sop.edu.plan.delete.url;
      // this.completeUrl = transactionConfig.sop.edu.annual.complete.url;
      // this.redirectUrl = selectConfig.com.mobileHostname.url;

      // if (this.param.eduEducationId) {
      //   this.tabItems = [
      //     // 기본정보
      //     { key: uid(), name: 'educationPlanInfo', icon: 'edit', label: 'LBLBASEINFO', component: () => import(`${'./educationPlanInfo.vue'}`) },
      //     // 교육대상자
      //     { key: uid(), name: 'educationResultTargetUser', icon: 'how_to_reg', label: 'LBL0002875', component: () => import(`${'./educationResultTargetUser.vue'}`), disabled: false },
      //     // 교육교재
      //     { key: uid(), name: 'educationTextBook', icon: 'checklist', label: 'LBL0002809', component: () => import(`${'./educationTextBook.vue'}`), disabled: false },
      //   ]
      // } else {
      //   this.tabItems = [
      //     // 기본정보
      //     { key: uid(), name: 'educationPlanInfo', icon: 'edit', label: 'LBLBASEINFO', component: () => import(`${'./educationPlanInfo.vue'}`) },
      //     // 교육대상자
      //     { key: uid(), name: 'educationResultTargetUser', icon: 'how_to_reg', label: 'LBL0002875', component: () => import(`${'./educationResultTargetUser.vue'}`), disabled: true },
      //     // 교육교재
      //     { key: uid(), name: 'educationTextBook', icon: 'checklist', label: 'LBL0002809', component: () => import(`${'./educationTextBook.vue'}`), disabled: true },
      //   ]
      // }
      this.getDetail();
    },
    tableDataChange() {
      // 총 교육 시간 계산
      let totalMinutes = 0;
      this.educationInfo.eduSubjectList.forEach(item => {
        if (item.educationTime) {
          const [hours, minutes] = item.educationTime.split(":").map(Number);
          totalMinutes += (hours * 60) + minutes;
        }
      });
      const totalHours = Math.floor(totalMinutes / 60);
      const remainingMinutes = totalMinutes % 60;
      // 계산된 총 시간을 표시, 앞에 '0'을 붙여 두 자리 숫자로 표시
      const formattedHours = totalHours.toString().padStart(2, '0');
      const formattedMinutes = remainingMinutes.toString().padStart(2, '0');
      this.educationInfo.sumEducationTime = `${formattedHours}:${formattedMinutes}`
    },
    getDetail() {
      if (this.param.eduEducationId) {
        this.$http.url = this.$format(this.getUrl, this.param.eduEducationId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          console.log(_result.data)
          this.educationInfo = _result.data;
          this.educationInfo.eduAttendeeList = _result.data.eduAttendeeList
          this.param.eduPlanTime = [_result.data.educationStartTime, _result.data.educationEndTime]
          // 상세팝업 최초작성/최근수정 정보 노출
          this.$emit('emitStep', {
            name: 'setRegInfo',
            param: _result.data,
          })
          if (this.educationInfo.educationRangeFlag !== 'Y') {
            this.educationDate = this.educationInfo.educationStartDate
          }
          this.tableDataChange()
          this.updateMode = true;
        },);
      } else if (this.param.eduCourseId) {
        this.$http.url = this.$format(selectConfig.sop.edu.course.get.url, this.param.eduCourseId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.forEach(this.educationInfo.eduSubjectList, item => {
            if (!this.educationInfo.delEduSubjectList) {this.educationInfo.delEduSubjectList = []}
            if (this.$_.findIndex(this.educationInfo.delEduSubjectList, { subjectNo: item.subjectNo }) === -1
              && item.editFlag !== 'C') {this.educationInfo.delEduSubjectList.push(item)}
            this.educationInfo.eduSubjectList = this.$_.reject(this.educationInfo.eduSubjectList, item)
          })
          // 빈값들 filter
          this.educationInfo.educationCourseName = _result.data.educationCourseName, // 교육과정명
          this.educationInfo.educationTypeCd = _result.data.educationTypeCd, // 교육구분
          // 교육과정 마스터 데이터 
          this.educationInfo.eduCourseId = _result.data.eduCourseId, // 교육과정 key
          this.educationInfo.educationCycle = _result.data.educationCycle, // 교육주기
          this.educationInfo.educationKindCdLarge = _result.data.educationKindCdLarge, // 교육종류 대
          this.educationInfo.educationKindCdSmall = _result.data.educationKindCdSmall, // 교육종류 소
          this.educationInfo.educationTypeCd = _result.data.educationTypeCd // 교육구분
          this.educationInfo.educationPurpose = _result.data.educationPurpose, // 학습목적
          this.educationInfo.legalEducationFlag = _result.data.legalEducationFlag, // 법정여부
          this.educationInfo.mainTargetAudience = _result.data.mainTargetAudience, // 주요대상자
          this.educationInfo.relationLaws = _result.data.relationLaws, // 관련법규
          this.educationInfo.executionBudget = _result.data.estimatedEducationExpenses // 예산
          this.educationInfo.sumEducationTime = _result.data.sumEducationTime // 교육시간
          this.educationInfo.eduQuestionFlag = _result.data.eduQuestionFlag
          this.educationInfo.eduQuestionMstId = _result.data.eduQuestionMstId
          this.educationInfo.eduQuestionPassScore = _result.data.eduQuestionPassScore
          this.educationInfo.educationLocation = _result.data.educationLocation
          this.educationInfo.educationMethodCd = _result.data.educationMethodCd
          this.educationInfo.vod = _result.data.vod
          this.educationInfo.educationName = _result.data.educationCourseName
          
          if (!this.educationInfo.delEduSubjectList) {this.educationInfo.delEduSubjectList = [];}
          this.$_.forEach(this.educationInfo.eduSubjectList, (item) => {
            if (this.$_.findIndex(this.educationInfo.delEduSubjectList, {subjectNo: item.subjectNo,}) === -1 && item.editFlag !== "C"
            ) {this.educationInfo.delEduSubjectList.push(item);}
            this.educationInfo.eduSubjectList = this.$_.reject(this.educationInfo.eduSubjectList, item);
          });
          this.educationInfo.eduSubjectList = [];
          if (_result.data.eduCourseSubjectList && _result.data.eduCourseSubjectList.length > 0) {
            this.$_.forEach(_result.data.eduCourseSubjectList, _item => {
              let index = this.$_.findIndex(this.educationInfo.eduSubjectList, {subjectNo: _item.subjectNo,});
              if (index === -1) {
                this.educationInfo.eduSubjectList.push({
                  eduEducationId: '',
                  subjectNo: _item.subjecNo,
                  subjectName: _item.subjectName, // 교육명
                  educationTime: _item.educationTime, // 교육시간
                  instructorName: _item.instructorName,
                  regUserId: this.$store.getters.user.userId,
                  editFlag: 'C'
                })
              }
            })
          } 
          this.tableDataChange();

          if (!this.educationInfo.delEduAttendeeList) {this.educationInfo.delEduAttendeeList = [];}
          this.$_.forEach(this.educationInfo.eduAttendeeList, (item) => {
            if (this.$_.findIndex(this.educationInfo.delEduAttendeeList, {attendeesId: item.attendeesId,}) === -1 && item.editFlag !== "C"
            ) {this.educationInfo.delEduAttendeeList.push(item);}
            this.educationInfo.eduAttendeeList = this.$_.reject(this.educationInfo.eduAttendeeList, item);
          });
          this.educationInfo.eduAttendeeList = [];
          if (_result.data.totalUserList && _result.data.totalUserList.length > 0) {
            this.$_.forEach(_result.data.totalUserList, _item => {
              let index = this.$_.findIndex(this.educationInfo.eduAttendeeList, {attendeesId: _item.userId,});
              if (index === -1) {
                this.educationInfo.eduAttendeeList.push({
                  eduEducationId: this.educationInfo.eduEducationId,
                  attendeesNo: uid(),
                  attendeesId: _item.userId,
                  attendeesTypeCd: 'EATC00003',
                  inExTypeCd: 'COURSE',
                  attendeesPositionName: _item.jobName,
                  attendanceTime: this.educationInfo.sumEducationTime,
                  attendeesDeptName: _item.deptName,
                  seniorityName: _item.seniorityName,
                  enterDate: _item.enterDate,
                  attendeesName: _item.userName,
                  regUserId: this.$store.getters.user.userId,
                  editFlag: 'C'
                })
              }
            })
          } 

        },);
      }
    },
    setData(data) {
      // if (data) {
      //   this.tabItems = [
      //     // 기본정보
      //     { key: uid(), name: 'educationPlanInfo', icon: 'edit', label: 'LBLBASEINFO', component: () => import(`${'./educationPlanInfo.vue'}`) },
      //     // 교육대상자
      //     { key: uid(), name: 'educationResultTargetUser', icon: 'how_to_reg', label: 'LBL0002875', component: () => import(`${'./educationResultTargetUser.vue'}`), disabled: false },
      //     // 교육교재
      //     { key: uid(), name: 'educationTextBook', icon: 'checklist', label: 'LBL0002809', component: () => import(`${'./educationTextBook.vue'}`), disabled: false },
      //   ]
      // }
      this.param.eduEducationId = data;
      this.getDetail();
    },
  }
};
</script>
