var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(_vm.component, {
        tag: "component",
        attrs: {
          param: _vm.param,
          educationDate: _vm.educationDate,
          educationInfo: _vm.educationInfo,
          saveCall: _vm.saveCall,
        },
        on: {
          "update:param": function ($event) {
            _vm.param = $event
          },
          "update:educationDate": function ($event) {
            _vm.educationDate = $event
          },
          "update:education-date": function ($event) {
            _vm.educationDate = $event
          },
          "update:educationInfo": function ($event) {
            _vm.educationInfo = $event
          },
          "update:education-info": function ($event) {
            _vm.educationInfo = $event
          },
          "update:saveCall": function ($event) {
            _vm.saveCall = $event
          },
          "update:save-call": function ($event) {
            _vm.saveCall = $event
          },
          setData: _vm.setData,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }